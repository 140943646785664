import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import FarmerDashboard from './FarmerDashboard';
import './farmer-section.scss';
import NotFound from '../../../util/components/NotFound';
import MarketingPackPage from '../../../pages/static/MarketingPackPage';
import QRPromoPage from '../../../pages/static/QRPromoPage';

const FarmerSection = ({ match, history }) => {
  return (
    <Switch>
      <Route exact path={ match.url } render={
        props => <FarmerDashboard { ...props } /> } />
      <Route path={ `${match.url}/marketing-pack` } render={
        props => <MarketingPackPage { ...props } /> } />
      <Route path={ `${match.url}/qr-labeling` } render={
        props => <QRPromoPage { ...props } /> } />
      <Route component={ NotFound } />
    </Switch>
  );
};

FarmerSection.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default FarmerSection;
