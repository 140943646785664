/* eslint-disable max-len */
import React from 'react';

const MarketSegmentsIcon = () => (
  <svg className="dashboard-icon market-segments" viewBox="0 0 130.52 155.72">
    <path className="background" d="M138,61.4l-38,51.18-39.6-50a63.68,63.68,0,0,0-23.73,42.63,63.75,63.75,0,0,0,56,70.66A62,62,0,0,0,133.21,167a59.3,59.3,0,0,0,18-16.4A63.76,63.76,0,0,0,138,61.4Z" transform="translate(-34.74 -22.14)"/>
    <path className="background" d="M138,36.4A62.93,62.93,0,0,0,60.4,37.62l39.6,50Z" transform="translate(-34.74 -22.14)"/>
    <path className="line" d="M164.55,103.05A64.85,64.85,0,0,0,138.9,60.2l-1.2-.9L100,110.12,60.64,60.51l-1.17.93A62.57,62.57,0,0,0,39.9,87.17h0l-.55,1.39L38.78,90h0a62.27,62.27,0,0,0-3.62,15.12A65.31,65.31,0,0,0,91,177.19v0l1.48.17h0l1.49.17v0c2.15.2,4.28.31,6.39.31A63.44,63.44,0,0,0,132.7,169v0l1.28-.79,1.28-.78v0a60,60,0,0,0,16.2-14.8l0,0,.89-1.21.89-1.2,0,0A64.82,64.82,0,0,0,164.55,103.05ZM138.3,63.51a62.32,62.32,0,0,1,12.55,85l-48.76-36.21ZM60.16,64.74l35.18,44.39-21.61-8.56L42.68,88.28A58.64,58.64,0,0,1,60.16,64.74ZM91.35,174.23a62.32,62.32,0,0,1-53.19-68.8,58.45,58.45,0,0,1,3.42-14.36l56.79,22.48L93.92,152Zm3,.34L101,117.08,118.61,146l12.53,20.53A60.7,60.7,0,0,1,94.33,174.57Zm39.37-9.65L111,127.69l-5.67-9.3,43.77,32.5A57,57,0,0,1,133.7,164.92Z" transform="translate(-34.74 -22.14)"/>
    <path className="line" d="M140.1,36.09l-1.2-.89a64.52,64.52,0,0,0-79.43,1.24l-1.18.94L100,90ZM62.52,37.88a61.51,61.51,0,0,1,73.38-1.16L100,85.12Z" transform="translate(-34.74 -22.14)"/>
  </svg>
);

export default MarketSegmentsIcon;
