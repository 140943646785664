import React, { useEffect } from 'react';
import Message from '../../user/signup/components/Message';
import './static-promo-page.scss';

const MarketingPackPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  return (
    <div className="promo-page">
      <div className="standard">
        <h1 className="title">
          Coming soon...
        </h1>
        <h5 className="plain-text-header">GoodBeef Index Marketing Pack</h5>
        <p className="para">
          { `The GoodBeef is in the process of compiling a selection of
          marketing material for farmers. We want to help you acheive 
          a fair price for a distinctive grade of quality. With our emphasis
          on authentication and verification our marketing materials are all
          designed to help you diffentiate your product` }
        </p>
        <p className="para">
          { `Get in touch to register your interest` }
        </p>
      </div>
      <div className="contact">
        <Message intro="" />
      </div>
    </div>
  );
};

export default MarketingPackPage;
