/* eslint-disable max-len */
import React from 'react';

const AccountAdminIcon = () => (
  <svg className="dashboard-icon account-admin" viewBox="0 0 130.72 130.72">
    <path className="background" d="M163.89,111V89H146.63a47.36,47.36,0,0,0-5.89-14.23l11.67-11.66L136.89,47.59,125.22,59.26A47.3,47.3,0,0,0,111,53.37V36.11h-22V53.37a47.3,47.3,0,0,0-14.22,5.89L63.17,47.59,47.65,63.11,59.31,74.78A47.56,47.56,0,0,0,53.42,89H36.17v22H53.42a47.56,47.56,0,0,0,5.89,14.22L47.65,136.83l15.52,15.52,11.66-11.66a47.56,47.56,0,0,0,14.22,5.89v17.25h22V146.58a47.56,47.56,0,0,0,14.22-5.89l11.67,11.66,15.52-15.52-11.67-11.66A47.3,47.3,0,0,0,146.63,111ZM100,121.27A21.3,21.3,0,1,1,121.32,100,21.3,21.3,0,0,1,100,121.27Z" transform="translate(-34.67 -34.61)"/>
    <path className="line" d="M100,77.18A22.8,22.8,0,1,0,122.82,100,22.82,22.82,0,0,0,100,77.18Zm0,42.59A19.8,19.8,0,1,1,119.82,100,19.82,19.82,0,0,1,100,119.77Z" transform="translate(-34.67 -34.61)"/>
    <path className="line" d="M100,69A31,31,0,1,0,131,100,31,31,0,0,0,100,69Zm0,58.9A28,28,0,1,1,128,100,28,28,0,0,1,100,127.92Z" transform="translate(-34.67 -34.61)"/>
    <path className="line" d="M165.39,112.45v-25H147.81A49,49,0,0,0,142.63,75l11.9-11.9L136.89,45.47,125,57.36a49,49,0,0,0-12.49-5.17V34.61h-25V52.19a49.11,49.11,0,0,0-12.49,5.17L63.17,45.47,45.53,63.11,57.42,75A49,49,0,0,0,52.24,87.5H34.67v25H52.24a49.14,49.14,0,0,0,5.18,12.49L45.53,136.83l17.64,17.64,11.89-11.89a48.75,48.75,0,0,0,12.49,5.17v17.58h25V147.75A48.61,48.61,0,0,0,125,142.58l11.9,11.89,17.64-17.64-11.9-11.89a49.14,49.14,0,0,0,5.18-12.49Zm-20.22-1.85a45.76,45.76,0,0,1-5.7,13.78l-.63,1,11.45,11.44-13.4,13.4-11.45-11.44-1,.63a46.07,46.07,0,0,1-13.77,5.7l-1.16.27v16.94h-19V145.39l-1.15-.27a46,46,0,0,1-13.78-5.7l-1-.63L63.17,150.23l-13.4-13.4,11.44-11.44-.63-1a46.13,46.13,0,0,1-5.7-13.78l-.27-1.15H37.67v-19H54.61l.27-1.16a46.07,46.07,0,0,1,5.7-13.77l.63-1L49.77,63.11l13.4-13.4L74.61,61.16l1-.63a45.76,45.76,0,0,1,13.78-5.7l1.15-.28V37.61h19V54.55l1.16.28a45.81,45.81,0,0,1,13.77,5.7l1,.63,11.45-11.45,13.4,13.4L138.84,74.56l.63,1a45.81,45.81,0,0,1,5.7,13.77l.28,1.16h16.94v19H145.45Z" transform="translate(-34.67 -34.61)"/>
  </svg>
);

export default AccountAdminIcon;
