/* eslint-disable max-len */
import React from 'react';

const QRLabellingIcon = () => (
  <svg className="dashboard-icon qr-labeling" viewBox="0 0 136.99 98.01">
    <path className="background" d="M128.84,21.06l-.26.17a10.71,10.71,0,0,0-8.61-9l-.15.1a10.69,10.69,0,0,0-10.56-9h-95A10.71,10.71,0,0,0,3.57,14V70A10.7,10.7,0,0,0,12.9,80.63l-.39.25A10.7,10.7,0,0,0,22,89.38l-.58.37a10.71,10.71,0,0,0,10.49,8.56h95a10.71,10.71,0,0,0,10.71-10.72v-56A10.71,10.71,0,0,0,128.84,21.06Z" transform="translate(-2.07 -1.8)"/>
    <path className="line" d="M129.82,19.75a12.21,12.21,0,0,0-8.76-8.84,12.22,12.22,0,0,0-11.8-9.11h-95A12.23,12.23,0,0,0,2.07,14V70a12.23,12.23,0,0,0,9.13,11.8A12.25,12.25,0,0,0,20,90.56a12.23,12.23,0,0,0,11.83,9.25h95a12.23,12.23,0,0,0,12.21-12.22v-56A12.22,12.22,0,0,0,129.82,19.75ZM5.07,70V14A9.23,9.23,0,0,1,14.29,4.8h95A9.22,9.22,0,0,1,118.47,14V70a9.22,9.22,0,0,1-9.21,9.21h-95A9.23,9.23,0,0,1,5.07,70Zm9.42,12.21h94.77A12.22,12.22,0,0,0,121.47,70V14.2a9.21,9.21,0,0,1,5.72,8.52v56A9.22,9.22,0,0,1,118,87.94H23A9.24,9.24,0,0,1,14.49,82.23Zm121.57,5.36a9.23,9.23,0,0,1-9.21,9.22h-95a9.26,9.26,0,0,1-8.58-5.87H118a12.23,12.23,0,0,0,12.21-12.21V23a9.22,9.22,0,0,1,5.87,8.59Z" transform="translate(-2.07 -1.8)"/>
    <rect className="line" x="78.74" y="19.25" width="23.23" height="3"/>
    <rect className="line" x="78.74" y="29.25" width="23.23" height="3"/>
    <rect className="line" x="78.74" y="39.25" width="23.23" height="3"/>
    <rect className="line" x="78.74" y="49.25" width="23.23" height="3"/>
    <rect className="line" x="78.74" y="59.25" width="23.23" height="3"/>
    <path className="line" d="M19.69,23.66a4.52,4.52,0,0,1,4.51-4.51h9.61v-3H24.2a7.51,7.51,0,0,0-7.51,7.51v9.6h3Z" transform="translate(-2.07 -1.8)"/>
    <path className="line" d="M65.81,23.66v9.6h3v-9.6a7.51,7.51,0,0,0-7.51-7.51H51.69v3H61.3A4.51,4.51,0,0,1,65.81,23.66Z" transform="translate(-2.07 -1.8)"/>
    <path className="line" d="M65.81,61.16a4.51,4.51,0,0,1-4.51,4.51H51.69v3H61.3a7.52,7.52,0,0,0,7.51-7.51V51.55h-3Z" transform="translate(-2.07 -1.8)"/>
    <path className="line" d="M24.2,68.67h9.61v-3H24.2a4.52,4.52,0,0,1-4.51-4.51V51.55h-3v9.61A7.52,7.52,0,0,0,24.2,68.67Z" transform="translate(-2.07 -1.8)"/>
    <path className="line" d="M24,38.84H39.3V23.5H24ZM27,26.5H36.3v9.34H27Z" transform="translate(-2.07 -1.8)"/>
    <path className="line" d="M24,61.27H39.3V45.93H24Zm3-12.34H36.3v9.34H27Z" transform="translate(-2.07 -1.8)"/>
    <path className="line" d="M61.46,23.5H46.12V38.84H61.46Zm-3,12.34H49.12V26.5h9.34Z" transform="translate(-2.07 -1.8)"/>
    <rect className="line" x="28.08" y="27.9" width="2.95" height="2.95"/>
    <rect className="line" x="21.67" y="39.28" width="2.95" height="2.95"/>
    <rect className="line" x="28.08" y="39.28" width="2.95" height="2.95"/>
    <rect className="line" x="34.25" y="39.28" width="2.95" height="2.95"/>
    <rect className="line" x="53.41" y="39.28" width="2.95" height="2.95"/>
    <rect className="line" x="39.64" y="51.15" width="2.95" height="2.95"/>
    <rect className="line" x="39.64" y="56.5" width="2.95" height="2.95"/>
    <rect className="line" x="45.06" y="56.5" width="2.95" height="2.95"/>
    <rect className="line" x="53.41" y="45.97" width="2.95" height="2.95"/>
    <rect className="line" x="39.16" y="24.11" width="2.95" height="2.95"/>
    <rect className="line" x="39.16" y="29.28" width="2.95" height="2.95"/>
    <rect className="line" x="28.08" y="50.33" width="2.95" height="2.95"/>
    <rect className="line" x="50.24" y="27.9" width="2.95" height="2.95"/>
    <path className="line" d="M41.69,50.75h11.5v-9.7H41.69Zm3-6.7h5.5v3.7h-5.5Z" transform="translate(-2.07 -1.8)"/>
    <rect className="line" x="45.06" y="51.12" width="12.83" height="3"/>
  </svg>
);

export default QRLabellingIcon;
