/* eslint-disable max-len */
import React from 'react';

const PricingIcon = () => (
  <svg data-cy="pricing-dashboard-icon" className="dashboard-icon pricing" viewBox="0 0 129.89 129.89">
    <polygon className="background" points="98.32 33.92 98.32 62.65 76.35 62.65 76.35 16.22 54.38 16.22 54.38 104.04 32.41 104.04 32.41 72.23 10.45 72.23 10.45 128.39 32.41 128.39 54.38 128.39 76.35 128.39 98.32 128.39 120.29 128.39 120.29 33.92 98.32 33.92"/>
    <path className="line" d="M156.85,161.94V67.48h-25V96.2h-19V49.78h-25V137.6H69V105.79H44v56.15H38.06V35.06h-3V164.94H164.94v-3ZM47,161.94V108.79H66v53.15Zm22,0V140.6h19v21.34Zm22,0V52.78h19V161.94Zm22,0V99.2h19v62.74Zm22,0V70.48h19v91.46Z" transform="translate(-35.06 -35.06)"/>
  </svg>
);

export default PricingIcon;
