import React, { useEffect } from 'react';
import Message from '../../user/signup/components/Message';
import './static-promo-page.scss';

const QPromoPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  return (
    <div className="promo-page">
      <div className="standard">
        <h1 className="title">
          GoodBeef Index QR Code labeling
        </h1>
        <p className="para">
          { `Labelling is a key component in the success of any food business.
          Our QR Code labels empower easy access to the GoodBeef Index 
          verification page. Consumers love our QR codes. They open up
          a wealth of information to authenticate the quality of your
          product. This is tracability on a level not yet seen in the UK
          beef industry.` }
        </p>
        <p className="para">
          { `With our simple labeling solutions you can chose from a 
          selection of designs to fit your requirements. All of our 
          labels include QR codes linking back to the GoodBeef Index
          verification page. Maybe you just need something small, something
          that adds a qr code to an existing labeling solution. Or perhaps
          you want a label that meets the regulatory requirement to include
          cutting plant and slaughter house identification codes. 
          Alternatively you might want to label specific cuts and include
          best before dates. Our QR Code labeling solutions are built 
          to support your business needs.` }
        </p>
        <p className="para">
          { `Get in touch to find out what the GoodBeef Index can do for you` }
        </p>
      </div>
      <div className="contact">
        <Message intro="" />
      </div>
    </div>
  );
};

export default QPromoPage;
