import React, { Fragment } from 'react';
import ArrowIcon from '../../../util/icons/components/ArrowIcon';
import MarketingIcon from './MarketingIcon';

const MarketingMenuItem = () => (
  <Fragment>
    <div className="icon-wrapper marketing"
      data-cy="menu-icon-marketing">
      <MarketingIcon />
    </div>
    <div className="text">
      <h3 className="menu-title">Marketing Pack</h3>
      <h5 className="find-out-more">
        <span className="text">Find out more</span>
        <ArrowIcon />
      </h5>
    </div>
  </Fragment>
);

export default MarketingMenuItem;
