import React, { useEffect } from 'react';
import './dashboard.scss';
import MarketSegmentationMenuItem from './MarketSegmentationMenuItem';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminMenuItem from './AdminMenuItem';
import ValueSharingMenuItem from './ValueSharingMenuItem';
import PricingMenuItem from './PricingMenuItem';
import MarketingMenuItem from './MarketingMenuItem';
import UltrasoundMenuIcon from './UltrasoundMenuIcon';
import QRLabellingMenuItem from './QRLabellingMenuItem';
import { getDashboardDomain } from '../../../util/site';

const FarmerDashboard = ({ match, location }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  const meta = 'GoodBeef Index provides a range of resources ' +
    'for beef farmers. Learn how quality beef is measured, ' +
    'grade your animals, and get industry insights';
  const admin = (
    <a className="menu-item"
      target="_blank"
      rel="noopener noreferrer"
      href={ `${getDashboardDomain()}/account`}>
      <AdminMenuItem />
    </a>
  );
  const valueSharing = (
    <a className="menu-item"
      target="_blank"
      rel="noopener noreferrer"
      href={ `${getDashboardDomain()}/for-farmers/value-sharing`}>
      <ValueSharingMenuItem />
    </a>
  );
  const pricing = (
    <a className="menu-item"
      target="_blank"
      rel="noopener noreferrer"
      href={ `${getDashboardDomain()}/for-farmers/pricing`}>
      <PricingMenuItem />
    </a>
  );
  const marketSegmentation = (
    <a className="menu-item"
      target="_blank"
      rel="noopener noreferrer"
      href={ `${getDashboardDomain()}/for-farmers/market-segmentation`}>
      <MarketSegmentationMenuItem />
    </a>
  );
  const marketing = (
    <Link className="menu-item" to={ `${match.url}/marketing-pack`}>
      <MarketingMenuItem />
    </Link>
  );
  const ultrasound = (
    <Link className="menu-item" to={ `/ultrasound`}>
      <UltrasoundMenuIcon />
    </Link>
  );
  const qrLabelling = (
    <Link className="menu-item" to={ `${match.url}/qr-labeling`}>
      <QRLabellingMenuItem />
    </Link>
  );
  // }
  return (
    <div id="farmer-section" className="dashboard">
      <div id="farmer-dashboard"className="main-dashboard">
        <Helmet>
          <title>
            GoodBeef Index Farmer Dashboard
          </title>
          <meta name="description" content={ meta } />
          <link rel="canonical"
            href={ `${window.location.origin}/${location.pathname}`} />
        </Helmet>
        <h2 className="sub-title">GoodBeef Index for farmers</h2>
        <h1 className="main-title">
          a fair price for an authenticated grade of quality
        </h1>
        <div className="menu">
          { admin }
          { valueSharing }
          { pricing }
          { marketSegmentation }
          { marketing }
          { ultrasound }
          { qrLabelling }
        </div>
      </div>
    </div>
  );
};

FarmerDashboard.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default FarmerDashboard;
