import React, { Fragment } from 'react';
import ArrowIcon from '../../../util/icons/components/ArrowIcon';
import MarketSegmentsIcon from './MarketSegmentsIcon';

const MarketSegmentationMenuItem = () => (
  <Fragment>
    <div className="icon-wrapper market-segments"
      data-cy="menu-icon-market-segments">
      <MarketSegmentsIcon />
    </div>
    <div className="text">
      <h3 className="menu-title">Market Segmentation</h3>
      <h5 className="find-out-more">
        <span className="text">Find out more</span>
        <ArrowIcon />
      </h5>
    </div>
  </Fragment>
);

export default MarketSegmentationMenuItem;
