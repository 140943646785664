import React, { Fragment } from 'react';
import ArrowIcon from '../../../util/icons/components/ArrowIcon';
import QRLabellingIcon from './QRLabellingIcon';

const QRLabellingMenuItem = () => (
  <Fragment>
    <div className="icon-wrapper qr-labeling"
      data-cy="menu-icon-qr-labeling">
      <QRLabellingIcon />
    </div>
    <div className="text">
      <h3 className="menu-title">QR Labeling</h3>
      <h5 className="find-out-more">
        <span className="text">Find out more</span>
        <ArrowIcon />
      </h5>
    </div>
  </Fragment>
);

export default QRLabellingMenuItem;
