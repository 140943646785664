import React, { Fragment } from 'react';
import ArrowIcon from '../../../util/icons/components/ArrowIcon';
import AccountAdminIcon from './AccountAdminIcon';

const AdminMenuItem = () => (
  <Fragment>
    <div className="icon-wrapper admin"
      onClick={ () => console.log('click') }
      data-cy="menu-icon-admin">
      <AccountAdminIcon />
    </div>
    <div className="text">
      <h3 className="menu-title">Account Admin</h3>
      <h5 className="find-out-more">
        <span className="text">Find out more</span>
        <ArrowIcon />
      </h5>
    </div>
  </Fragment>
);

export default AdminMenuItem;
