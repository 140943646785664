/* eslint-disable max-len */
import React from 'react';

const MarketingIcon = () => (
  <svg className="dashboard-icon marketing" viewBox="0 0 139.4 114.99">
    <path className="background" d="M131.08,23.62V13.71s-4.14.14-10.38.8V3.33a131.5,131.5,0,0,0-26.39,8.9A109,109,0,0,0,71.53,26.69,109,109,0,0,0,48.75,12.23a131.52,131.52,0,0,0-26.38-8.9V14.51c-6.24-.66-10.39-.8-10.39-.8v9.91c-5.37.21-8.65.48-8.65.48v88.32a326.89,326.89,0,0,1,34.1-.44c17,.68,31.15,2.61,33.69,3a2.82,2.82,0,0,0,.82,0c2.55-.36,16.65-2.29,33.69-3a326.89,326.89,0,0,1,34.1.44V24.1S136.45,23.83,131.08,23.62Z" transform="translate(-1.83 -1.5)"/>
    <path className="line" d="M139.85,22.6s-2.78-.22-7.27-.42v-10l-1.55.06c-.16,0-3.55.12-8.83.63V1.5l-1.8.36a131.81,131.81,0,0,0-26.73,9A111.62,111.62,0,0,0,71.53,24.74,111.62,111.62,0,0,0,49.39,10.88a131.6,131.6,0,0,0-26.73-9L20.87,1.5V12.85c-5.28-.51-8.68-.63-8.84-.63l-1.55-.06v10c-4.49.2-7.24.42-7.27.42l-1.38.12V114l1.62-.13a329.37,329.37,0,0,1,33.92-.43c16.93.67,30.91,2.58,33.54,3a4.3,4.3,0,0,0,.62,0,4.21,4.21,0,0,0,.62,0c2.63-.38,16.61-2.29,33.54-3a329.63,329.63,0,0,1,33.93.43l1.61.13V22.72ZM121,93.12l1.21-.24v-77c3.26-.32,5.85-.49,7.38-.57v85.32A181.38,181.38,0,0,0,101,104.47c-7.53,1.74-15.26,4.12-21.06,6a104.6,104.6,0,0,1,15-8.6A130.92,130.92,0,0,1,121,93.12ZM95,13.59A132.52,132.52,0,0,1,119.2,5.21V90.44a133.64,133.64,0,0,0-25.52,8.75A108,108,0,0,0,73,111.83V27.39A110,110,0,0,1,95,13.59ZM23.87,5.21a132.77,132.77,0,0,1,24.25,8.38A109.81,109.81,0,0,1,70,27.39v84.44A107.68,107.68,0,0,0,49.38,99.19a133.86,133.86,0,0,0-25.51-8.75ZM13.48,15.29c1.54.08,4.12.25,7.39.57v77l1.2.24a130.92,130.92,0,0,1,26.05,8.79,104.05,104.05,0,0,1,15,8.6c-5.79-1.92-13.53-4.3-21-6a181.46,181.46,0,0,0-28.62-3.86ZM4.83,110.81V25.49c1.2-.08,3.15-.2,5.65-.31v78.3l1.45,0a181.67,181.67,0,0,1,29.49,3.86c5.56,1.29,11.25,2.93,16.17,4.47-5.64-.54-12.58-1.08-20.1-1.38C22.28,109.88,9.26,110.54,4.83,110.81Zm133.4,0c-4.43-.27-17.45-.93-32.66-.33-7.51.3-14.45.84-20.1,1.38,4.92-1.54,10.61-3.18,16.17-4.47a181.79,181.79,0,0,1,29.49-3.86l1.45,0V25.18c2.5.11,4.45.23,5.65.31Z" transform="translate(-1.83 -1.5)"/>
  </svg>
);

export default MarketingIcon;
